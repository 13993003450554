<template>
    <div>

        <a-table bordered :data-source="list" :pagination="false">

            <a-table-column title="序号" width="50px" align="center" fixed="left">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>

            <a-table-column title="月份">
                <template slot-scope="text">
                    <span v-if="disabled">
                        {{ text.date }}
                    </span>
                    <span v-else>
                        <a-month-picker placeholder="选择月份" size="small" :allowClear="false" v-model="text.date" />
                    </span>
                </template>
            </a-table-column>

            <a-table-column title="附件">
                <template slot-scope="text">
                    <Upload :list="text.fileList" :disabled="disabled" />
                </template>
            </a-table-column>

            <a-table-column title="操作" align="center" v-if="!disabled">
                <template slot-scope="text, row, index">
                    <a href="#" class="danger" @click.prevent="deleteText(index)">删除</a>
                </template>
            </a-table-column>

        </a-table>
    </div>
</template>
  
<script>
import Upload from './upload.vue'

export default {

    components: {
        Upload,
    },

    props: {
        list: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    methods: {

        deleteText(index) {
            this.$emit('delete', index)
        }

    },
};
</script>
export const amts = [
    {
        name: '勘察设计费',
    },
    {
        name: '设备费',
    },
    {
        name: '土建费'
    },
    {
        name: '安装费',
    },
    {
        name: '暂列金',
    },
    {
        name: '其他费',
    }
]

export const exps = [
    {
        name: '设备分包',
        sort: '1',
    },
    {
        name: '勘察设计费分包',
        sort: '2',
    },
    {
        name: '其他费分包',
        sort: '3',
    }
]
<template>
    <div>

        <a-table bordered :data-source="list" :pagination="false">

            <a-table-column title="序号" width="50px" align="center" fixed="left">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>

            <a-table-column title="名称">
                <template slot-scope="text">
                    <span>
                        {{ text.name }}
                    </span>
                </template>
            </a-table-column>

            <a-table-column title="含税" align="right">
                <template slot-scope="text">

                    <span style="color: #1890ff">
                        {{ text.amt ? text.amt.toLocaleString() + text.unit : '' }}

                    </span>



                </template>
            </a-table-column>

            <a-table-column title="不含税" align="right">
                <template slot-scope="text">

                    <span style="color: #1890ff">
                        {{ text.exTaxAmt ? text.exTaxAmt.toLocaleString() : '' }}
                    </span>

                </template>
            </a-table-column>



        </a-table>
    </div>
</template>
  
<script>

export default {

    props: {
        list: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },



    methods: {

        deleteText(index) {
            this.$emit('delete', index)
        }

    },
};
</script>
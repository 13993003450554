<template>
    <div>

        <a-table bordered :data-source="totalList" :pagination="false">

            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === totalList.length - 1 ? '合计' : index + 1 }}
                </template>
            </a-table-column>

            <a-table-column title="名称">
                <template slot-scope="text, row, index">
                    <span v-if="index === totalList.length - 1"></span>
                    <span v-else>
                        <span v-if="disabled">{{ text.name }}</span>
                        <span v-else>


                            <span v-if="amts.findIndex(item => item.name === text.name) > -1">
                                {{ text.name }}
                            </span>
                            <a-input size="small" v-model="text.name" v-else />

                        </span>
                    </span>
                </template>
            </a-table-column>

            <a-table-column title="合同金额(元)" align="right">
                <template slot-scope="text, row, index">
                    <span v-if="index === totalList.length - 1" style="color: #1890ff">
                        {{ text.amt.toLocaleString() }}
                    </span>
                    <span v-else>

                        <span v-if="disabled" style="color: #1890ff">{{ typeof text.amt === 'number' ?
                            text.amt.toLocaleString() : text.amt }}</span>
                        <a-input-number v-else size="small" :formatter="$inputNumberFormat" style="width: 120px;"
                            v-model="text.amt" />

                    </span>

                </template>
            </a-table-column>

            <a-table-column title="税率" align="right">
                <template slot-scope="text, row, index">
                    <span v-if="index === totalList.length - 1"></span>
                    <span v-else>
                        <span v-if="disabled">{{ text.taxRate + '%' }}</span>
                        <a-input-number v-else size="small" :min="0" :max="100" :formatter="$ratioFormatter"
                            style="width: 120px;" v-model="text.taxRate" />
                    </span>

                </template>
            </a-table-column>

            <a-table-column title="不含税金额(元)" align="right">
                <template slot-scope="text, row, index">
                    <span v-if="index === totalList.length - 1" style="color: #1890ff">
                        {{ text.exTaxAmt.toLocaleString() }}
                    </span>
                    <span v-else>


                        <span style="color: #1890ff">
                            {{ getMoney(text).toLocaleString() }}
                        </span>
                    </span>
                </template>
            </a-table-column>

            <a-table-column title="操作" align="center" v-if="!disabled">
                <template slot-scope="text, row, index">
                    <span v-if="index === totalList.length - 1"></span>
                    <span v-else>
                        <a href="#" class="danger" @click.prevent="deleteText(index)">删除</a>
                    </span>
                </template>
            </a-table-column>

        </a-table>
    </div>
</template>
  
<script>
import accurate from 'accurate'
import { amts } from './data';
export default {

    props: {
        totalList: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            amts
        }
    },

    methods: {

        getMoney(text) {
            if (typeof text.amt === 'number' && typeof text.taxRate === 'number') {
                return accurate.expr(`(100 - ${text.taxRate}) * ${text.amt} * 0.01`)
            } else {
                return 0;
            }
        },

        deleteText(index) {
            this.$emit('delete', index)
        }

    },
};
</script>
<template>
    <div>
        <input ref="upload" style="display: none" type="file" :multiple="true" @change="onChange" />

        <a-space>
            <a v-for="item in list" :key="item" :href="item" target="_blank">
                {{ $getFileName(item) }}
            </a>
            <a-button type="primary" size="small" :loading="loading" @click="onClick" v-if="!disabled">
                上传
            </a-button>
        </a-space>

    </div>
</template>
  
<script>
import { uploadFile } from "@/api/upload";
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        onClick() {
            this.$refs.upload.click();
        },

        onChange(e) {
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("files", files[i]);
            }

            this.loading = true;

            uploadFile(data)
                .then((res) => {
                    console.log("upload res", res);
                    if (Array.isArray(res)) {
                        this.list.push(...res.map(item => item.completePath))
                    }
                })
                .finally(() => {
                    this.loading = false;
                    e.target.value = null;
                });
        },
    },
};
</script>
<template>
    <div>

        <a-table bordered :data-source="[...list, all]" :pagination="false" rowKey="sort">

            <a-table-column title="序号" width="50px" align="center" fixed="left">
                <template slot-scope="text">
                    {{ text.sort }}
                </template>
            </a-table-column>

            <a-table-column title="费用名称">
                <template slot-scope="text">
                    <span v-if="text.sort === '合计'">

                    </span>
                    <span v-else>
                        <span v-if="disabled">
                            {{ text.name }}
                        </span>
                        <span v-else>
                            <span v-if="exps.findIndex(item => item.name === text.name) > -1">
                                {{ text.name }}
                            </span>
                            <a-input v-else size="small" v-model="text.name" />


                        </span>
                    </span>

                </template>
            </a-table-column>

            <a-table-column title="费用金额(元)" align="right">
                <template slot-scope="text">


                    <span v-if="text.sort === '合计'" style="color: #1890ff">

                        {{ typeof text.amt === 'number' ? text.amt.toLocaleString() : text.amt }}

                    </span>
                    <span v-else>


                        <span v-if="disabled">
                            <span style="color: #1890ff">
                                {{ typeof text.amt === 'number' ? text.amt.toLocaleString() : text.amt }}
                            </span>
                        </span>
                        <span v-else>
                            <span v-if="isCategory(text)" style="color: #1890ff">
                                {{ getCategoryMoney(text).toLocaleString() }}
                            </span>
                            <a-input-number v-else size="small" :formatter="$inputNumberFormat" style="width: 120px;"
                                v-model="text.amt" />
                        </span>

                    </span>



                </template>
            </a-table-column>

            <a-table-column title="税率" align="right">
                <template slot-scope="text">

                    <span v-if="text.sort === '合计'"></span>

                    <span v-else>
                        <span v-if="isCategory(text)">
                        </span>
                        <span v-else>
                            <span v-if="disabled">
                                {{ text.taxRate + '%' }}
                            </span>
                            <a-input-number v-else size="small" :min="0" :max="100" :formatter="$ratioFormatter"
                                style="width: 120px;" v-model="text.taxRate" />
                        </span>

                    </span>



                </template>
            </a-table-column>

            <a-table-column title="不含税费用金额(元)" align="right">
                <template slot-scope="text">

                    <span v-if="text.sort === '合计'" style="color: #1890ff">

                        {{ typeof text.exTaxAmt === 'number' ? text.exTaxAmt.toLocaleString() : text.exTaxAmt }}

                    </span>
                    <span v-else>



                        <span>
                            <span v-if="isCategory(text)" style="color: #1890ff">
                                {{ getCategoryMoneyWithoutTax(text).toLocaleString() }}
                            </span>
                            <span v-else style="color: #1890ff">
                                {{ getMoney(text).toLocaleString() }}
                            </span>
                        </span>
                    </span>




                </template>
            </a-table-column>
            <a-table-column title="供应商名称">
                <template slot-scope="text">

                    <span v-if="text.sort === '合计'"></span>

                    <span v-else>
                        <span v-if="isCategory(text)">
                        </span>
                        <span v-else>

                            <span v-if="disabled">
                                <span style="cursor:pointer;color: #1890ff;">
                                    {{ text.supplyName }}
                                </span>
                            </span>

                            <span v-else>
                                <CompanySelector @change="company => onSelectCompany(company, text)">
                                    <span style="cursor:pointer;color: #1890ff;">
                                        {{ text.supplyName ? text.supplyName : '请选择' }}
                                    </span>
                                </CompanySelector>
                            </span>




                        </span>
                    </span>


                </template>
            </a-table-column>

            <a-table-column title="操作" align="right" v-if="!disabled">
                <template slot-scope="text, row, index">

                    <span v-if="text.sort === '合计'"></span>

                    <span v-else>

                        <span v-if="isCategory(text)">
                            <a-space>
                                <a href="#" @click.prevent="add(text, index)">新增</a>
                                <a href="#" class="danger" v-if="canIDelete(text)" @click.prevent="deleteText(index)">删除</a>
                            </a-space>
                        </span>
                        <span v-else>
                            <a href="#" class="danger" @click.prevent="deleteText(index)">删除</a>
                        </span>

                    </span>


                </template>
            </a-table-column>

        </a-table>
    </div>
</template>
  
<script>
import accurate from 'accurate'
import CompanySelector from "@/components/company-selector";
import { exps } from './data';
export default {

    components: {
        CompanySelector,
    },

    props: {
        list: {
            type: Array,
        },
        all: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            exps,
        }
    },

    methods: {

        // 判断是不是费用分类
        isCategory(text) {
            return text.sort.indexOf('.') === -1
        },

        add(text, categoryIndex) {

            let index = -1;
            this.list.forEach((item, idx) => {
                if (item.sort.indexOf(text.sort + '.') === 0) {
                    index = idx;
                }
            })

            if (index === -1) {
                // 没有子序号，那就后面追加.1
                this.list.splice(categoryIndex + 1, 0, {
                    sort: text.sort + '.1'
                    // sort: accurate.add(text.sort, 0.1)
                })
            } else {
                // 如果已经有了子序号，就在最新的子序号的基础上增加1
                const last = this.list[index]
                const newValue = parseInt(last.sort.split('.')[1]) + 1;
                this.list.splice(index + 1, 0, {
                    // sort: accurate.add(last.sort, 0.1)
                    sort: text.sort + '.' + newValue
                })
            }

        },

        canIDelete(text) {
            return this.list.filter(item => item.sort.indexOf(text.sort + '.') === 0).length === 0;
        },

        getMoney(text) {

            if (typeof text.amt === 'number' && typeof text.taxRate === 'number') {

                return accurate.expr(`(100 - ${text.taxRate}) * ${text.amt} * 0.01`)

            } else {
                return 0;
            }

        },

        deleteText(index) {
            this.$emit('delete', index)
        },

        // 获取这个分类下所有的
        getCategoryMoney(text) {

            const arr = this.list.filter(item => item.sort.indexOf(text.sort + '.') === 0)

            let amt = 0;
            arr.forEach(item => {
                if (typeof item.amt === 'number') {
                    amt = accurate.add(amt, item.amt)
                }
            })

            return amt;
        },

        getCategoryMoneyWithoutTax(text) {
            const arr = this.list.filter(item => item.sort.indexOf(text.sort + '.') === 0)

            let exTaxAmt = 0;

            arr.forEach(item => {
                if (typeof item.amt === 'number') {
                    exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item))
                }
            })

            return exTaxAmt;
        },

        onSelectCompany(company, text) {
            text.supplyName = company.name
            this.$forceUpdate();
        }
    },
};
</script>